<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '中间件编辑' : '中间件创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-divider orientation="left" style="margin-top: 0">基本配置</a-divider>
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="中间件类型" prop="middlewareType">
        <a-select
          v-model="form.middlewareType"
          :disabled="isUpdate"
          :options="options"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="所属操作系统" prop="os">
        <source-select
          v-model="form.os"
          :disabled="isUpdate"
          source-type="os"
        ></source-select>
      </a-form-model-item>
      <a-form-model-item prop="groups">
        <group-select
          v-model="form.groups"
          source-type="middleware"
        ></group-select>
        <question-icon
          slot="label"
          title="所属群组"
          description="用于分组查询及告警配置"
        ></question-icon>
      </a-form-model-item>
      <a-divider orientation="left">监控配置</a-divider>
      <template v-if="form.middlewareType === 'nginx'">
        <a-alert
          message="通过 Nginx 提供的 stub_status 页面获取监控数据。"
          :show-icon="true"
          style="margin-bottom: 16px; border-radius: 8px;"
          banner
          type="info"
        ></a-alert>
        <a-form-model-item label="IP 地址">
          <a-input
            v-model="form.macro.host"
            :max-length="128"
            placeholder="默认为 localhost"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="监控端口">
          <a-input
            v-model="form.macro.port"
            :max="65535"
            :min="1"
            type="number"
            placeholder="默认为 80"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="stub_status 路径">
          <a-input
            v-model="form.macro.path"
            :max-length="128"
            placeholder="默认为 stub_status"
          ></a-input>
        </a-form-model-item>
      </template>
      <template v-else-if="form.middlewareType === 'iis'">
        <a-form-model-item label="监控端口">
          <a-input
            v-model="form.macro.port"
            :max="65535"
            :min="1"
            type="number"
            placeholder="默认为 80"
          ></a-input>
        </a-form-model-item>
      </template>
      <template v-else-if="form.middlewareType === 'php_fpm'">
        <a-alert
          message="通过 PHP-FPM 提供的 ping 和 status 页面获取监控数据。"
          :show-icon="true"
          style="margin-bottom: 16px; border-radius: 8px;"
          banner
          type="info"
        ></a-alert>
        <a-form-model-item label="IP 地址">
          <a-input
            v-model="form.macro.host"
            :max-length="128"
            placeholder="默认为 localhost"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="监控端口">
          <a-input
            v-model="form.macro.port"
            :max="65535"
            :min="1"
            type="number"
            placeholder="默认为 80"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="进程名称">
          <a-input
            v-model="form.macro.name"
            :max-length="128"
            placeholder="默认为 php-fpm"
          ></a-input>
        </a-form-model-item>
      </template>
      <template v-else-if="form.middlewareType === 'rabbitmq'">
        <a-alert
          message="通过 RabbitMQ Management 获取监控数据。"
          :show-icon="true"
          style="margin-bottom: 16px; border-radius: 8px;"
          banner
          type="info"
        >
          <template slot="description">
            插件安装: rabbitmq-plugins enable rabbitmq_management
          </template>
        </a-alert>
        <a-form-model-item label="IP 地址">
          <a-input
            v-model="form.macro.host"
            :max-length="128"
            placeholder="默认为 127.0.0.1"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="监控端口">
          <a-input
            v-model="form.macro.port"
            :max="65535"
            :min="1"
            type="number"
            placeholder="默认为 15672"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户名">
          <a-input
            v-model="form.macro.user"
            :max-length="128"
            placeholder="默认为 guest"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input-password
            v-model="form.macro.password"
            autocomplete="new-password"
            :max-length="128"
            placeholder="默认为 guest"
            :visibility-toggle="!isUpdate"
          ></a-input-password>
        </a-form-model-item>
      </template>
      <template v-else-if="form.middlewareType === 'apache'">
        <a-alert
          message="通过 Apache 提供的 status 页面获取监控数据。"
          :show-icon="true"
          style="margin-bottom: 16px; border-radius: 8px;"
          banner
          type="info"
        ></a-alert>
        <a-form-model-item label="IP 地址">
          <a-input
            v-model="form.macro.host"
            :max-length="128"
            placeholder="默认为 127.0.0.1"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="监控端口">
          <a-input
            v-model="form.macro.port"
            :max="65535"
            :min="1"
            type="number"
            placeholder="默认为 80"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="status 路径">
          <a-input
            v-model="form.macro.path"
            :max-length="128"
            placeholder="默认为 server-status?auto"
          ></a-input>
        </a-form-model-item>
      </template>
      <template v-else-if="form.middlewareType === 'tomcat'">
        <a-alert
          message="通过 JMX 来获取 Tomcat 监控数据。"
          :show-icon="true"
          style="margin-bottom: 16px; border-radius: 8px;"
          banner
          type="info"
        >
          <template slot="description">
            <div>添加以下参数至 CATALINA_OPTS 中:</div>
            <div>
              -Dcom.sun.management.jmxremote
              -Dcom.sun.management.jmxremote.authenticate=false
              -Dcom.sun.management.jmxremote.ssl=false
              -Dcom.sun.management.jmxremote.rmi.port=12345
              -Dcom.sun.management.jmxremote.port=12345
              -Dcom.sun.management.jmxremote.host=0.0.0.0
              -Djava.rmi.server.hostname=IP 地址
            </div>
          </template>
        </a-alert>
        <a-form-model-item label="URI">
          <a-input
            v-model="form.macro.uri"
            :max-length="128"
            placeholder="service:jmx:rmi:///jndi/rmi://<IP 地址>:<监控端口>/jmxrmi"
          ></a-input>
        </a-form-model-item>
      </template>
      <template v-else-if="form.middlewareType === 'kafka'">
        <a-alert
          message="通过 JMX 来获取 Kafka 监控数据。"
          :show-icon="true"
          style="margin-bottom: 16px; border-radius: 8px;"
          banner
          type="info"
        >
          <template slot="description">
            <div>添加以下参数至 CATALINA_OPTS 中:</div>
            <div>
              -Dcom.sun.management.jmxremote
              -Dcom.sun.management.jmxremote.authenticate=false
              -Dcom.sun.management.jmxremote.ssl=false
              -Dcom.sun.management.jmxremote.rmi.port=12345
              -Dcom.sun.management.jmxremote.port=12345
              -Dcom.sun.management.jmxremote.host=0.0.0.0
              -Djava.rmi.server.hostname=IP 地址
            </div>
          </template>
        </a-alert>
        <a-form-model-item label="URI">
          <a-input
            v-model="form.macro.uri"
            :max-length="128"
            placeholder="service:jmx:rmi:///jndi/rmi://<IP 地址>:<监控端口>/jmxrmi"
          ></a-input>
        </a-form-model-item>
      </template>
      <template v-else-if="form.middlewareType === 'tongweb'">
        <a-alert
          message="通过 JMX 来获取 TongWeb 监控数据。"
          :show-icon="true"
          style="margin-bottom: 16px; border-radius: 8px;"
          banner
          type="info"
        >
          <template slot="description">
            <div>添加以下参数至 CATALINA_OPTS 中:</div>
            <div>
              -Dcom.sun.management.jmxremote
              -Dcom.sun.management.jmxremote.authenticate=false
              -Dcom.sun.management.jmxremote.ssl=false
              -Dcom.sun.management.jmxremote.rmi.port=12345
              -Dcom.sun.management.jmxremote.port=12345
              -Dcom.sun.management.jmxremote.host=0.0.0.0
              -Djava.rmi.server.hostname=IP 地址
            </div>
          </template>
        </a-alert>
        <a-form-model-item label="URI">
          <a-input
            v-model="form.macro.uri"
            :max-length="128"
            placeholder="service:jmx:rmi:///jndi/rmi://<IP 地址>:<监控端口>/jmxrmi"
          ></a-input>
        </a-form-model-item>
      </template>
      <template v-else-if="form.middlewareType === 'zookeeper'">
        <a-form-model-item label="监控端口">
          <a-input
            v-model="form.macro.port"
            :max="65535"
            :min="1"
            type="number"
            placeholder="默认为 80"
          ></a-input>
        </a-form-model-item>
      </template>
      <template v-else-if="form.middlewareType === 'tonghttpserver'">
        <a-form-model-item label="IP 地址">
          <a-input
            v-model="form.macro.host"
            :max-length="128"
            placeholder="默认为 localhost"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="监控端口">
          <a-input
            v-model="form.macro.port"
            :max="65535"
            :min="1"
            type="number"
            placeholder="默认为 80"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="API 路径">
          <a-input
            v-model="form.macro.path"
            :max-length="128"
            placeholder="默认为 api/http/monitor/format/json"
          ></a-input>
        </a-form-model-item>
      </template>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading.confirm" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
          <a-button
            v-if="!monitorActiveMode"
            :loading="loading.test"
            type="primary"
            @click="test"
            style="background: #ffa040; border-color: #ffa040"
          >
            测试
          </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  createMiddleware,
  getMiddleware,
  getMiddlewareMonitorMacro,
  updateMiddleware,
  testMiddleware
} from '@/api/middleware'
import QuestionIcon from '@/components/icon/QuestionIcon.vue'
import GroupSelect from '@/components/select/GroupSelect.vue'
import SourceSelect from '@/components/select/SourceSelect.vue'
import { middlewareTypes } from '@/utils/const'

export default {
  name: 'InputDrawer',
  components: {
    GroupSelect,
    QuestionIcon,
    SourceSelect
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        middlewareType: 'nginx',
        os: undefined,
        groups: undefined,
        macro: {}
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        middlewareType: [
          {
            message: '请选择中间件类型',
            required: true,
            trigger: 'change'
          }
        ],
        os: [
          {
            message: '请选择所属操作系统',
            required: true,
            trigger: 'change'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      options: middlewareTypes.map(value => {
        return {
          title: this.$t(`middleware_type.${value}`),
          value
        }
      }),
      loading: {
        confirm: false,
        test: false
      }
    }
  },
  computed: {
    ...mapGetters(['monitorActiveMode'])
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getMiddleware(id)
          .then(res => {
            const data = res.data
            this.form.id = data.id
            this.form.name = data.name
            this.form.middlewareType = data.middleware_type
            this.form.os = data.os
            this.form.groups = data.groups
          })
          .finally(() => {
            this.visible = true
          })
        getMiddlewareMonitorMacro(id).then(res => {
          this.form.macro = res.data
        })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        middlewareType: 'nginx',
        os: undefined,
        groups: undefined,
        macro: {}
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading.confirm = true
          const params = {
            name: this.form.name
          }
          if (this.form.groups) {
            params.group_ids = this.form.groups.map(group => group.id)
          }
          if (Object.keys(this.form.macro).length !== 0) {
            params.macro = this.form.macro
          }
          if (this.isUpdate) {
            updateMiddleware(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              }).finally(() => {
                this.loading.confirm = false
              })
          } else {
            params.middleware_type = this.form.middlewareType
            params.os_id = this.form.os.id
            createMiddleware(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              }).finally(() => {
                this.loading.confirm = false
              })
          }
        }
      })
    },
    test () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            os_id: this.form.os.id,
            macro: this.form.macro,
            middleware_type: this.form.middlewareType
          }
          this.loading.test = true
          testMiddleware(params)
            .then(res => {
              this.$message.success(res.message)
            })
            .finally(() => {
              this.loading.test = false
            })
        }
      })
    }
  },
  watch: {
    'form.middlewareType' () {
      this.form.macro = {}
    }
  }
}
</script>
